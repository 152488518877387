<template>
  <div class="home">
    <div class="centerHome">
      <div class="banner">
        <div class="search">
          <div class="centerHome">
            <el-radio-group v-model="select">
              <el-radio-button label="1" class="search-radio">{{
                $t("homeView.search.journal")
              }}</el-radio-button>
              <el-radio-button label="2" class="search-radio">{{
                $t("homeView.search.article")
              }}</el-radio-button>
              <el-radio-button label="3" class="search-radio">{{
                $t("homeView.search.keyword")
              }}</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 15px">
              <el-input
                :placeholder="$t('homeView.search.placeholder')"
                v-model="input"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="bannerText">
          <div class="bannerTitle">{{ $t("homeView.banner.title") }}</div>
          <div class="bannerDesc">
            {{ $t("homeView.banner.subTitle") }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="search">
      <div class="centerHome">
        <div class="search-box">
          <div style="margin-top: 15px">
            <el-input
              placeholder="Please enter the content"
              v-model="input"
              class="input-with-select"
            >
              <el-select
                v-model="select"
                slot="prepend"
                placeholder="Please select"
              >
                <el-option label="Journal Title" value="1"></el-option>
                <el-option label="Article Title" value="2"></el-option>
                <el-option label="keyword" value="3"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div> -->

    <div class="centerHome">
      <div class="journalList">
        <h1 class="title">{{ $t("homeView.content.journal") }}</h1>
        <div class="journalBox">
          <div
            class="journalItem"
            v-for="(item, index) in journalList"
            :key="index"
          >
            <div class="journalImg">
              <img :src="item.thumbnail.url" alt="" />
            </div>
            <div class="journalText">
              <h3 class="journalTitle" @click="toDetailPages(item)">
                {{ item.title }}
              </h3>
              <p class="journalDesc" v-show="item.onlineIssn">
                eISSN: {{ item.onlineIssn }}
              </p>
              <p class="journalDesc" v-show="item.printIssn">
                ISSN: {{ item.printIssn }}
              </p>
              <p class="journalDesc" v-html="item.publicationFrequency"></p>
              <div class="other">
                <p class="journalDesc" v-show="item.citescore">
                  Citescore: {{ item.citescore }}
                </p>
                <p class="journalDesc" v-show="item.frequency">
                  Impact Factor: {{ item.frequency }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="more" v-if="journalList.length > 0">
          <span @click="toJournalList">
            {{ $t("homeView.content.more") }}
            <i class="el-icon-d-arrow-right"></i>
          </span>
        </div>
      </div>
      <div class="articleList">
        <h1 class="title">{{ $t("homeView.content.articles") }}</h1>
        <div class="box" :class="{ visible: visible }">
          <div class="articleBox">
            <!-- @click="toArticleDetail(item)" -->
            <div
              class="articleItem"
              v-for="(item, index) in articleList"
              :key="index"
              @click="toArticleDetail(item)"
            >
              <div class="journalName">{{ item.journalTitle }}</div>
              <div class="onlineTime">
                <i class="el-icon-date"></i>{{ item.datePublished }}
              </div>
              <div class="articleTitle" style="text-align: left">
                {{ item.fullTitle }}
              </div>
              <div class="articleType">{{ item.section }}</div>
              <br />
              <div class="doi">{{ item.doi }}</div>
            </div>
          </div>
          <div class="more" v-if="articleList.length > 0">
            <span @click="toJournalList">
              {{ $t("homeView.content.more") }}
              <i class="el-icon-d-arrow-right"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      input: "",
      select: "1",
      journalList: [],
      articleList: [],
      // loading: false,
      // loadArticles: false,
      visible: false,
    };
  },
  mounted() {
    this.getJournalList();
    this.getArticlesList();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.visible = true;
          observer.unobserve(entry.target);
        }
      });
    });

    const target = this.$el.querySelector(".articleBox");
    observer.observe(target);
  },
  methods: {
    getJournalList() {
      // this.loading = true;
      // &isIndex=true
      get("index/api/v1/journals?count=8&offset=0").then((res) => {
        if (res.code == 200) {
          this.journalList = res.data.items;
          this.$nextTick(() => {
            const p = document.querySelectorAll(".journalDesc p");
            p.forEach((p) => {
              p.style.lineHeight = "1.5rem";
            });
          });
        } else {
          this.$message.console.error(res.message);
        }
      });
    },
    getArticlesList() {
      // this.loadArticles = true;
      get("/index/api/v1/submissions/allLatest?count=8&offset=0").then(
        (res) => {
          if (res.code == 200) {
            this.articleList = res.data.items;
          } else {
            this.$message.console.error(res.message);
          }
        }
      );
    },

    toJournalList() {
      this.$router.push({
        path: "/journalList",
      });
    },
    toDetailPages(item) {
      localStorage.setItem("journal", JSON.stringify(item.path));
      this.$router.push({
        path: "/detailPages/" + item.path,
        query: {
          journal: item.path,
        },
      });
    },
    toArticleDetail(item) {
      this.$router.push({
        path: "/detailPages/" + item.journalPath + "/articalDetail",
        query: {
          journal: item.journalPath,
          id: item.id,
        },
      });
    },
    handleSearch() {
      if (this.select == "1") {
        this.$router.push({
          name: "journalList",
          query: {
            searchCriteria: this.input,
          },
        });
      } else {
        this.$router.push({
          name: "advancedSearch",
          query: {
            searchCriteria: this.input,
            type: this.select,
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/commen.css");
.home {
  background-color: #ffffff;
  .centerHome {
    width: 98%;
    max-width: 1400px;
    margin: auto;
    padding: 10px 0;
    @media (max-width: 996px) {
      width: 100vw;
      max-width: 100%;
      margin: 0 4px;
    }
    .banner {
      width: 1400px;
      height: 200px;
      background: url("@/assets/images/homebannner.jpg") #ffffff no-repeat
        center;
      box-shadow: #ececec 0px 0px 10px;
      position: relative;
      overflow: hidden;
      background-size: 100% 100%;
      @media (max-width: 996px) {
        width: 100%;
        height: 150px;
      }
      .search {
        position: absolute;
        top: 45%;
        left: 38%;
        width: 60%;
        transform: translate(-50%, -50%);
        animation: slide-upss 1s forwards;
        @keyframes slide-upss {
          0% {
            top: 70%;
            opacity: 0;
          }
          100% {
            top: 45%;
            opacity: 1;
          }
        }
        @media (max-width: 996px) {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          animation: slide-upss 1s forwards;
          @keyframes slide-upss {
            0% {
              top: 70%;
              opacity: 0;
            }
            100% {
              top: 0%;
              opacity: 1;
            }
          }
        }
        .centerHome {
          width: 100%;
          max-width: 1400px;
          margin: auto;
          @media (max-width: 996px) {
            position: none;
            width: 80%;
            max-width: 80%;
          }
        }
      }
      .bannerText {
        position: absolute;
        top: 50%;
        left: 80%;
        transform: translate(-50%, -50%);
        animation: slide-up 1s forwards;
        @media (max-width: 996px) {
          display: none;
        }
        @keyframes slide-up {
          0% {
            top: 70%;
            opacity: 0;
          }
          100% {
            top: 50%;
            opacity: 1;
          }
        }

        .bannerTitle {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 10px;
          line-height: 38px;
          width: auto;
          color: #fff;
          // border-bottom: 2px double #585858;
        }
        .bannerDesc {
          font-size: 14px;
          line-height: 14px;
          color: #fff;
        }
      }
    }
    .journalList {
      .journalBox {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        min-height: 200px;
        animation: slide-ups 0.5s forwards;
        @keyframes slide-ups {
          0% {
            top: 80%;
            opacity: 0;
          }
          100% {
            top: 0;
            opacity: 1;
          }
        }
        .journalItem {
          width: calc(27% - 28px);
          height: 245px;
          margin: 5px 0;
          background: #ffffff;
          box-shadow: #ececec 0px 0px 10px;
          padding: 5px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          border-radius: 3px;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          transition: all 0.6s;
          @media (max-width: 996px) {
            width: 100%;
            height: auto;
            padding: 10px;
            margin: 0 10px 10px;
          }
          .journalImg {
            width: 35%;
            height: auto;
            overflow: hidden;
            flex-shrink: 0;
            img {
              width: 100%;
              height: auto;
            }
          }
          .journalText {
            width: calc(65% - 10px);
            padding: 5px 0 0 10px;
            color: #333;
            position: relative;
            .journalTitle {
              margin-bottom: 6px;
              cursor: pointer;
              @media (max-width: 996px) {
                font-size: 15px;
              }
            }
            .journalDesc {
              line-height: 1;
              font-size: 14px;
              color: #585858;
            }
            .other {
              position: absolute;
              // left: 0;
              bottom: 0px;
            }
          }
        }
        .journalItem:hover {
          transition: all 0.6s;
          border: 1px solid #f19600;
          // box-sizing: border-box;
          .journalText {
            .journalTitle {
              color: #f19600;
            }
          }
        }
      }
      .more {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 15px;
        color: #666;
        cursor: pointer;
        transition: all 0.6s;
        i {
          transform: rotate(90deg);
        }
      }
      .more:hover {
        margin-top: 20px;
        transition: all 0.6s;
        color: #a40000;
      }
    }
    .articleList {
      .box {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 1.5s ease, transform 1.5s ease;
        .articleBox {
          display: flex;
          flex-wrap: wrap;
          min-height: 200px;
          .articleItem {
            transition: all 0.6s;
            width: calc(25% - 30px);
            padding: 20px;
            box-sizing: border-box;
            margin: 15px;
            border: 1px solid #ececec;
            @media (max-width: 996px) {
              width: 100%;
              height: auto;
              padding: 10px;
              margin: 10px;
            }
            .journalName {
              font-size: 14px;
              color: #0f7497;
              margin-bottom: 5px;
            }
            .onlineTime {
              color: #cccccc;
              font-size: 14px;
              margin-bottom: 5px;
              i {
                margin-right: 5px;
              }
            }
            .articleTitle {
              font-size: 16px;
              font-weight: bold;
              justify-content: space-between;
              line-height: 1.5;
              margin-bottom: 5px;
              cursor: pointer;
              height: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .articleType {
              font-size: 14px;
              color: #ffffff;
              padding: 3px 8px;
              border-radius: 5px;
              text-align: center;
              background: #f19600;
              display: inline-block;
              margin-bottom: 5px;
            }
            .doi {
              // display: block;
              color: #585858;
              font-size: 14px;
              word-wrap: break-word;
            }
          }
          .articleItem:hover {
            // border: 1px solid #f19600;
            box-shadow: #585858 0px 0px 10px;
            transition: all 0.6s;
            .articleTitle {
              color: #f19600;
              text-decoration: underline;
            }
          }
        }
        .more {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
          margin-top: 15px;
          color: #666;
          cursor: pointer;
          transition: all 0.6s;
          i {
            transform: rotate(90deg);
          }
        }
        .more:hover {
          margin-top: 20px;
          transition: all 0.6s;
          color: #a40000;
        }
      }
      .visible {
        opacity: 1; /* 可见时的状态 */
        transform: translateY(0); /* 回到原始位置 */
      }
    }
  }
}
</style>
<style scoped>
.el-select {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
@media (max-width: 996px) {
  h1 {
    font-size: 20px;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    height: 26px;
    line-height: 25px;
    width: 25px;
  }
  .el-input__inner {
    height: 30px !important;
  }
  .el-input--medium .el-input__inner,
  .el-input__inner {
    height: 100% !important;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    height: 100% !important;
  }
}
</style>
